import React, { useContext, useState } from 'react'
import { styled } from 'linaria/react'
import SiteButton from '../../Core/SiteButton'
import VbCheckbox from '../../Core/Form/VbCheckbox'
import { useIntl } from '@jetshop/intl'
import ChannelContext from '@jetshop/core/components/ChannelContext'
import { useNewsletterSubscription } from '@jetshop/core/hooks/Subscriptions/useNewsletterSubscription'
import BtnContentArrowLoading from '../../Auth/UI/FormComps/BtnContentArrowLoading'

const Container = styled.div``
const Wrapper = styled.div``
const InputWrapper = styled('div')``
const ErrorText = styled.div`
   color: red;
   font-size: 16px;
   font-weight: 600;
   display: flex;
   gap: 0.5rem;
   align-items: center;
`

const ConsentLabel = styled.div`
   a {
      color: black;
      font-size: 1rem;
      font-weight: 600;
   }
`
const CustomFieldContainer = styled.div`
   input {
      min-height: 3.75rem;
      border-radius: 0.25rem;
      border: solid 3px #707070;
      background-color: #fbfbfb;
      font-family: var(--font-grotesk);
      font-size: 20px;
      font-weight: 600;
      width: 100%;
      text-indent: 1rem;
   }

   input::placeholder {
      color: #000;
      opacity: 0.43;
   }

   .form-control:focus {
      border-color: #195178;
   }
`

const NewsLetterForm = ({ showConfirmPage, showWelcomePage }) => {
   const t = useIntl()
   const [consent, setConsent] = useState(false)
   const selectedChannel = useContext(ChannelContext)?.selectedChannel

   const {
      inputProps,
      submit,
      submitting,
      submitted,
      failed,
      errorStates: { alreadySubscribed, invalidEmail },
   } = useNewsletterSubscription({
      enabled: true,
   })

   const getConsentLink = () => {
      switch (selectedChannel?.id) {
         case 1:
            return '/integritetspolicy'
         case 2:
            return '/integritetspolitik'
         case 3:
            return '/personvernerkaering'
         default:
            return '/integritetspolicy'
      }
   }

   const handleConsentChange = event => {
      setConsent(event.target.checked)
   }

   const disabled = inputProps.value.length === 0 || submitting

   if (submitted && !alreadySubscribed && !invalidEmail && !failed) {
      showConfirmPage(true)
   }

   return (
      <Container>
         <Wrapper>
            {alreadySubscribed && (
               <ErrorText>
                  {t('You have already subscribed to the newsletter!')}
               </ErrorText>
            )}

            {(invalidEmail || failed) && (
               <ErrorText>
                  {t(
                     'Something went wrong. Please check your email and try again.'
                  )}
               </ErrorText>
            )}

            <InputWrapper>
               <form onSubmit={submit} disabled={disabled}>
                  <CustomFieldContainer>
                     <input
                        type="email"
                        id="newsletter"
                        placeholder={t('Din mailadress...')}
                        data-testid="newsletter-subscription-input"
                        {...inputProps}
                     />
                  </CustomFieldContainer>
                  <VbCheckbox
                     id="consent"
                     name="consent"
                     className="mt-16 d-flex gap-2"
                     checked={consent}
                     onChange={handleConsentChange}
                     label={
                        <ConsentLabel>
                           {t('consent-confirm')}{' '}
                           <a
                              className="link link--vb link--black"
                              href={getConsentLink()}
                              target="_blank"
                              rel="noreferrer"
                           >
                              {t('readmore')}
                           </a>
                        </ConsentLabel>
                     }
                  />
                  <SiteButton
                     loading={submitting}
                     className="mt-16 py-8 px-16"
                     type="submit"
                     styleType="solid"
                     color="blue"
                     icon={true}
                     disabled={disabled || !consent}
                     data-testid="newsletter-subscription-submit"
                  >
                     {t('register')}
                     <BtnContentArrowLoading loading={submitting} />
                  </SiteButton>
               </form>
            </InputWrapper>
         </Wrapper>
      </Container>
   )
}

export default NewsLetterForm
