import { SignupContext } from '@jetshop/core/components/Auth/signup-context'
import t from '@jetshop/intl'
import CheckboxGroup from '@jetshop/ui/Checkbox/CheckboxGroup'
import React, { useContext } from 'react'
import { css } from 'linaria'
import { theme } from '../../Theme'

const checkboxWrapStyle = css`
   margin-bottom: 1rem;
   display: flex;
   ${theme.above.md} {
      margin-bottom: 1.5rem;
   }
   ${theme.above.md} {
      margin-bottom: 2rem;
   }
   label {
      margin-top: 0;
      font-family: var(--font-industry);
      font-size: ${theme.font.size.l};
      justify-content: flex-start;
      align-items: center;
      + label {
         margin-left: 1.5em;
      }
   }
   ${theme.below.md} {
      label {
         font-size: ${theme.font.size.m};
      }
   }

   label > span {
      width: 17px;
      height: 17px;
      border: 2px solid gray !important;
   }

   svg > path {
      stroke: #2F80ED !important;
   }
`

// Checkbox group variables
const items = [
   { label: t('Private'), val: 'private' },
   { label: t('Company'), val: 'company' },
]

export function CustomerType() {
   const { isUserTypeBusiness, setIsUserTypeBusiness } =
      useContext(SignupContext)

   function handleChange(type) {
      setIsUserTypeBusiness(type === 'company')
   }

   const selectedItem = !isUserTypeBusiness ? items[0].val : items[1].val

   return (
      <CheckboxGroup
         items={items}
         selectedItem={selectedItem}
         handleChange={handleChange}
         className={checkboxWrapStyle}
      />
   )
}
