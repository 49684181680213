import React from 'react'

/**
 * A container component that vertically centers its child elements, adds vertical spacing,
 * and places gaps between each child element.
 *
 * @param {*} children
 * @returns A vertically centered container with added spacing and gaps between children.
 */
const HeaderContent = ({ children }) => {
   return (
      <div className="mb-32 p-0 d-flex gap-3 align-items-center">
            {children}
      </div>
   )
}
export default HeaderContent


