import LogInFormProvider from '@jetshop/ui/Auth/LogInFormProvider'
import React, { useEffect, useState } from 'react'
import { GlobalError } from '../Forms/GlobalError'
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig'
import { useIntl } from '@jetshop/intl'
import { LogIn } from 'iconoir-react'
import SiteButton from '../Core/SiteButton'
import { NavArrowRight } from 'iconoir-react'
import InputWithLabel from '../Forms/InputWithLabelVB'
import CenteredContainer from './UI/AuthPagesComps/CenteredContainer'
import ContainerHeaderContent from './UI/AuthPagesComps/HeaderContent'
import BtnContentArrowLoading from './UI/FormComps/BtnContentArrowLoading'
import {
   PageBanner as LogInBanner,
   AuthPageHeaderTitle as LogInBannerTitle,
   AuthContentHeaderTitle as LoginContentHeaderTitle,
} from './UI/authPages'
import { FieldsWrapper, FormButtonsContainer } from './UI/Form'

function LogInPage() {
   const [loading, setLoading] = useState(true)
   const { routes } = useShopConfig()
   const t = useIntl()

   useEffect(() => {
      setLoading(false)
   }, [setLoading])

   if (loading) {
      return <div style={{ height: '972px', width: '100%' }}></div>
   }

   return (
      <div>
         <LogInFormProvider redirect="my-pages">
            {({ globalError, isSubmitting, isValid }) => (
               <>
                  <LogInBanner>
                     <LogInBannerTitle className="py-32 px-128">
                        {t('login-to-your-account')}
                     </LogInBannerTitle>
                  </LogInBanner>
                  <CenteredContainer className="">
                     <ContainerHeaderContent>
                        <LogIn
                           strokeWidth={2.0}
                           width={38}
                           height={38}
                           style={{ marginLeft: '-8px' }}
                        ></LogIn>
                        <LoginContentHeaderTitle
                           style={{
                              marginBlockEnd: '0px',
                              marginBlockStart: '0px',
                           }}
                        >
                           {t('Login')}
                        </LoginContentHeaderTitle>
                     </ContainerHeaderContent>
                     <section>
                        <FieldsWrapper>
                           <InputWithLabel
                              label={t('E-mail address')}
                              placeholder={t('provide your email address')}
                              id={'email'}
                              type={'email'}
                              name={'email'}
                              required={true}
                              disableValidation={false}
                           />
                           <InputWithLabel
                              label={t('Password')}
                              placeholder={t('provide your password')}
                              id={'password'}
                              type={'password'}
                              name="password"
                              required={true}
                              disableValidation={false}
                           />
                        </FieldsWrapper>
                        {globalError && (
                           <GlobalError>{globalError}</GlobalError>
                        )}
                        <FormButtonsContainer>
                           <SiteButton
                              className="py-8 px-16"
                              type="submit"
                              styleType="solid"
                              color="blue"
                              icon={true}
                              // disabled={isValid}
                           >
                              <span
                                 style={{
                                    marginRight: '2rem',
                                 }}
                              >
                                 {t('Login')}
                              </span>
                              <BtnContentArrowLoading loading={isSubmitting} />
                           </SiteButton>
                           <SiteButton
                              className="py-8 px-16"
                              styleType="hollow"
                              color="blue"
                              icon={true}
                              href={routes.signup.path}
                           >
                              <span
                                 style={{
                                    marginRight: '2rem',
                                 }}
                              >
                                 {t('Not yet a member? Sign Up')}
                              </span>
                              <NavArrowRight
                                 strokeWidth={2.0}
                                 style={{ marginLeft: '0.3rem' }}
                              ></NavArrowRight>
                           </SiteButton>
                           <SiteButton
                              className="py-8 px-16"
                              styleType="hollow"
                              color="blue"
                              icon={true}
                              href={routes.forgotPassword.path}
                           >
                              <span
                                 style={{
                                    marginRight: '2rem',
                                 }}
                              >
                                 {t('Forgot password?')}
                              </span>
                              <NavArrowRight
                                 strokeWidth={2.0}
                                 style={{ marginLeft: '0.3rem' }}
                              ></NavArrowRight>
                           </SiteButton>
                        </FormButtonsContainer>
                     </section>
                  </CenteredContainer>
               </>
            )}
         </LogInFormProvider>
      </div>
   )
}

export default LogInPage
