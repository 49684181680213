import useLoginFields from '@jetshop/core/components/Auth/useLoginFields'
import React from 'react'
import InputWithLabel from '../../Forms/InputWithLabelVB'

export default function LoginFields({...props }) {
   const { fields } = useLoginFields()
   return (
      <section>
         <div className="d-flex row">
            {fields.map(field => {
               return (
                  <div key={field.name} className="col-12 col-lg-6 mb-16">
                     <InputWithLabel {...field.inputProps} />
                     {/* <Input {...field.inputProps} onFocus={focusSection} /> */}
                  </div>
               )
            })}
         </div>
      </section>
   )
}