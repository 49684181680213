import {
   ForgotPasswordProvider,
   useForgotPasswordData,
} from '@jetshop/core/components/Auth/ForgotPasswordProvider'
// import t from '@jetshop/intl'
import React from 'react'
import { GlobalError } from '../Forms/GlobalError'
import InputWithLabel from '../Forms/InputWithLabelVB'
import { useIntl } from '@jetshop/intl'
import SiteButton from '../Core/SiteButton'
import { LockKey, NavArrowRight } from 'iconoir-react'
import CenteredContainer from './UI/AuthPagesComps/CenteredContainer'
import ContainerHeaderContent from './UI/AuthPagesComps/HeaderContent'
import BtnContentArrowLoading from './UI/FormComps/BtnContentArrowLoading'
import {
   PageBanner as ForgotPasswordBanner,
   AuthPageHeaderTitle as ForgotPasswordTitle,
   AuthContentHeaderTitle as ForgotPasswordContentHeaderTitle,
   authHeaderIconProps,
} from './UI/authPages'
import { FieldsWrapper, FormButtonsContainer } from './UI/Form'

export default function ForgotPasswordPage(props) {
   const t = useIntl()

   return (
      <ForgotPasswordProvider>
         <ForgotPasswordBanner>
            <ForgotPasswordTitle className="py-32 px-128">
               {t(`Forgot password`)}
            </ForgotPasswordTitle>
         </ForgotPasswordBanner>
         <CenteredContainer>
            <ContainerHeaderContent>
               <LockKey {...authHeaderIconProps}></LockKey>
               <ForgotPasswordContentHeaderTitle
                  style={{ marginBlockEnd: '0px' }}
               >
                  {t('Återställ lösenord')}
               </ForgotPasswordContentHeaderTitle>
            </ContainerHeaderContent>
            <ForgotPasswordForm />
         </CenteredContainer>
      </ForgotPasswordProvider>
   )
}

function ForgotPasswordForm() {
   const { submitted, isSubmitting, globalError, isValid, emailInputProps } =
      useForgotPasswordData()
   const t = useIntl()

   if (submitted) {
      return (
         <p style={{ lineHeight: 1.5 }}>
            {t(
               `Your password reset request has been submitted. Check your email for a link to reset your password.`
            )}
         </p>
      )
   }

   return (
      <section>
         <FieldsWrapper>
            <InputWithLabel
               label={t('email adress')}
               placeholder={t('provide your email address')}
               {...emailInputProps}
               required={true}
               disableValidation={false}
            />
         </FieldsWrapper>

         {globalError && <GlobalError>{globalError}</GlobalError>}

         <FormButtonsContainer>
            <SiteButton
               className="py-8 px-16"
               type="submit"
               styleType="solid"
               color="blue"
               icon={true}
               disabled={!isValid}
            >
               <span
                  style={{
                     marginRight: '2rem',
                  }}
               >
                  {t('Submit')}
               </span>

               <BtnContentArrowLoading loading={isSubmitting} />
            </SiteButton>
         </FormButtonsContainer>
      </section>
   )
}
