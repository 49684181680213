import { theme } from '../../../Theme'
import { styled } from 'linaria/react'
import React, { useState } from 'react'

const ContentContainer = styled.div`
   background-color: white;
   border-radius: 1rem;
   box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.16);
   width: 100%;
   
   p {
      font-weight: 500;
      font-size: ${theme.font.size.l}
   }

   ${theme.above.md} {
      flex-basis: ${props => props.initialWidth ? props.initialWidth : '60rem'};
      p {
         font-size: ${theme.font.size.xl}
      }
   }
`

/**
 * A container component that centers its children horizontally and vertically on the screen.
 * It utilizes flexbox for centering and provides additional spacing around the content.
 *
 * @param {*} children - The child elements to be centered within the container.
 * @returns A centered container with added spacing around the content.
 */
const CenteredContainer = ({ initialWidth, children }) => {
   return (
      <div className="container-fluid p-0 d-flex justify-content-center">
         <ContentContainer initialWidth={initialWidth} className="py-64 px-64 mb-128 mt-64 mx-32">
            {children}
         </ContentContainer>
      </div>
   )
}

export default CenteredContainer
