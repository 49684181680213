import { theme } from '../../Theme'
import { styled } from 'linaria/react'

export const PageBanner = styled.div`
   background-color: #195178;
   color: white;
   text-transform: uppercase;
   padding: 0;

   ${theme.below.md} {
      padding-top: 1.75rem;
   }
`

/** @returns H-tag without default top/bottom-margin */
export const AuthPageHeaderTitle = styled('h1')`
   font-family: var(--font-grotesk);
   color: white;
   text-decoration: none;
   font-weight: 600;
   margin-block-start: 0px;
   margin-block-end: 0px;
`
export const AuthContentHeaderTitle = styled('h2')`
   font-family: var(--font-industry);
   text-decoration: none;
   font-weight: 600;
`

export const authHeaderIconProps = {
   strokeWidth: 2.0,
   width: 34,
   height: 34,
 };
