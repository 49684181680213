import t, { useIntl } from '@jetshop/intl'
import useSignupForm from '@jetshop/core/components/Auth/useSignupForm'
import { css } from 'linaria'
import React, { useContext } from 'react'
import { GlobalError } from '../../Forms/GlobalError'
import { FormButtonsContainerSmallMarginTop } from '../UI/Form'
import SiteButton from '../../Core/SiteButton'
import BtnContentArrowLoading from '../UI/FormComps/BtnContentArrowLoading'
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig'
import { SignupFormikContext } from '@jetshop/core/components/Auth/signup-context'
import Link from '../../Core/Link'
import { conforms } from 'lodash'
import { NavArrowRight } from 'iconoir-react'

const sectionStyle = css`
   margin-top: 2em;
`

export const useSignUpResponseStatus = () => {
   const {
      formik: { status },
   } = useContext(SignupFormikContext)
   const { routes } = useShopConfig()
   const t = useIntl()

   const showError = status?.isSignupError ?? false

   let errorTitle = ''
   let errorMessage = t('Something went wrong, please review your information')
   let errorGuidance = {
      suggestionMessage: '',
      linkTitle: '',
      linkPath: '',
   }

   switch (status?.validationDetails?.[0]) {
      case 'EmailAddress':
         errorTitle = ''
         if (status?.validationDetails?.[1] === 'UniqueValueAlreadyExists') {
            errorMessage = `${t(
               'You have already signed up with this email address'
            )}.`
            errorGuidance = {
               suggestionMessage: '',
               linkTitle: t('Forgot password?'),
               linkPath: routes.forgotPassword.path,
            }
         }
         break

      case 'Pid':
         errorTitle = ''
         if (status?.validationDetails?.[1] === 'InvalidValue' || status?.validationDetails?.[1] === 'MaxLength') {
            errorMessage = t('Your social security number is invalid')
            errorGuidance = {
               suggestionMessage: '',
               linkTitle: '',
               linkPath: '',
            }
         }
         break

      case 'MobilePhone':
         errorTitle = ''
         if (status?.validationDetails?.[1] === 'InvalidValue') {
            errorMessage = t('Your phone number is invalid')
            errorGuidance = {
               suggestionMessage: '',
               linkTitle: '',
               linkPath: '',
            }
         }
         break

      default:
         break
   }

   return {
      showError,
      errorTitle,
      errorMessage,
      errorGuidance,
   }
}

export const SignUpResponseError = () => {
   const { showError, errorTitle, errorMessage, errorGuidance } =
      useSignUpResponseStatus()

   return showError ? (
      <GlobalError>
         <>
            {errorTitle && <p>{errorTitle}</p>}
            {errorMessage && <span>{errorMessage}</span>}
            {errorGuidance?.suggestionMessage && (
               <>
                  {' '}
                  <span>{errorGuidance?.suggestionMessage}</span>
               </>
            )}
            {errorGuidance?.linkPath && (
               <>
                  {' '}
                  <Link
                     to={errorGuidance?.linkPath}
                     className="simple-text-link"
                  >
                     <span>
                        {errorGuidance?.linkTitle
                           ? `${errorGuidance?.linkTitle} `
                           : ''}
                        {/* <NavArrowRight size="12px" color="#195178" /> */}
                     </span>
                  </Link>
               </>
            )}
         </>
      </GlobalError>
   ) : <></>
}

export default function SubmitSignUp({ ...props }) {
   const { isSubmitting } = useSignupForm()
   const t = useIntl()

   return (
      <section>
         <SignUpResponseError />
         <FormButtonsContainerSmallMarginTop>
            <SiteButton
               className="w-50 py-16 px-16"
               type="submit"
               styleType="solid"
               color="blue"
               icon={true}
               disabled={isSubmitting}
               data-testid="sign-up-button"
            >
               <span
                  style={{
                     marginRight: '2rem',
                  }}
               >
                  {t('Create Profile')}
               </span>
               <BtnContentArrowLoading loading={isSubmitting} />
            </SiteButton>
         </FormButtonsContainerSmallMarginTop>
      </section>
   )
}
