import { Field } from 'formik'
import { theme } from '../../Theme'
import VbCheckbox from '../../Core/Form/VbCheckbox'
import { styled } from 'linaria/react'
import React from 'react'
import { useIntl } from '@jetshop/intl'

const SignUpPreferencesWrapper = styled.div`
   .signup-preferences-label {
      margin-top: 0;
      font-family: var(--font-industry);
      font-size: ${theme.font.size.l};
      justify-content: flex-start;
      align-items: center;
      margin-left: 0.5em;
   }
   ${theme.below.md} {
      .signup-preferences-label {
         font-size: ${theme.font.size.m};
      }
   }
`

export default function Preferences({ ...props }) {
   const t = useIntl()
   return (
      <SignUpPreferencesWrapper className="py-16 d-flex gap-3 flex-wrap">
         <Field name={'preferences.type.acceptsEmail'}>
            {({ field }) => (
               <VbCheckbox
                  {...field}
                  id={'preferences.type.acceptsEmail'}
                  label={
                     <span className="signup-preferences-label">
                        {t('Accept email')}
                     </span>
                  }
                  checked={field.value}
               />
            )}
         </Field>
         <Field name={'preferences.type.acceptsSms'}>
            {({ field }) => (
               <VbCheckbox
                  {...field}
                  id={'preferences.type.acceptsSms'}
                  label={
                     <span className="signup-preferences-label">
                        {t('Accept SMS')}
                     </span>
                  }
                  checked={field.value}
               />
            )}
         </Field>
      </SignUpPreferencesWrapper>
   )
}
