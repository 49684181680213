import SignupFormProvider from '@jetshop/core/components/Auth/SignupFormContainer'
import useAuth from '@jetshop/core/components/AuthContext/useAuth'
// import t from '@jetshop/intl'
import React from 'react'
import { Redirect } from 'react-router'
import LoadingPage from '../../LoadingPage'
import Address from './Address'
import LoginFields from './LoginFields'
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig'
import { useIntl } from '@jetshop/intl'
import {
   PageBanner as SignUpBanner,
   AuthPageHeaderTitle as SignUpBannerTitle,
   AuthContentHeaderTitle as SignUpContentHeaderTitle,
   authHeaderIconProps,
} from '../UI/authPages'
import CenteredContainer from '../UI/AuthPagesComps/CenteredContainer'
import ContainerHeaderContent from '../UI/AuthPagesComps/HeaderContent'
import { AddUser } from 'iconoir-react'
import SubmitSignUp from './SubmitSignUp'
import Preferences from './Preferences'

function SignUpPage() {
   const { loggedIn } = useAuth()
   const { routes } = useShopConfig()
   const t = useIntl()

   if (loggedIn) {
      return <Redirect to={routes.myPages.path} />
   }

   return (
      <SignupFormProvider
         LoadingComponent={<LoadingPage />}
         additionalInitialValues={{
            preferences: {
               type: {
                  acceptsEmail: false,
                  acceptsSms: false,
               },
            },
         }}
      >
         <SignUpBanner>
            <SignUpBannerTitle className="py-32 px-64">
               {t('register account')}
            </SignUpBannerTitle>
         </SignUpBanner>
         <CenteredContainer initialWidth="70rem">
            <ContainerHeaderContent>
               <AddUser {...authHeaderIconProps}></AddUser>
               <SignUpContentHeaderTitle style={{ marginBlockEnd: '0px' }}>
                  {t('Sign up')}
               </SignUpContentHeaderTitle>
            </ContainerHeaderContent>
            <p className="mb-32">{t('sign up welcom message')}</p>
            <Address className="mb-64" />
            <LoginFields />
            <Preferences />
            <SubmitSignUp />
         </CenteredContainer>
      </SignupFormProvider>
   )
}

export default SignUpPage
