import { SignupContext } from '@jetshop/core/components/Auth/signup-context'
import React, { useContext } from 'react'
import InputWithLabel from '../../Forms/InputWithLabelVB'
import ChannelContext from '@jetshop/core/components/ChannelContext'
import { getPIDPlaceholder } from '../../Core/Util/Helpers'

export function PID() {
   const { allFields } = useContext(SignupContext)
   const channelId = useContext(ChannelContext)?.selectedChannel?.id
   const pidPlaceholder = getPIDPlaceholder(channelId)

   const pid = allFields.find(
      field => field.id === 'pid' || field.id === 'organizationNumber'
   )

   if (!pid) return null

   return (
      <InputWithLabel
         type={pid.type}
         name={pid.name}
         label={pid.label}
         required={pid.required}
         placeholder={pid.id === 'pid' ? pidPlaceholder : ""}
      ></InputWithLabel>
   )
}
