import useAddressFields from '@jetshop/core/components/Auth/useAddressFields'
import React, { useEffect } from 'react'
import { PID } from './PID'
import { CustomerType } from './CustomerType'
import InputWithLabel from '../../Forms/InputWithLabelVB'
import { useIntl } from '@jetshop/intl'
import { CountriesInput } from '@jetshop/ui/Auth/FormFields/CountriesInput'

export default function AddressFieldsVb({ className, ...props }) {
   const { fields, setCountryByCode, countries } = useAddressFields()
   const t = useIntl()

   return (
      <section className={className}>
         <h2 className="mb-32">{t('Contact Details')}</h2>
         <p className="mb-32">
            {t(
               'Please fill in your contact details for shipping and communication.'
            )}
         </p>

         <CustomerType />

         <div className="mb-32">
            {countries.length > 1 ? (
               <>
                  <p className="mb-2">{t('countries')}</p>
                  <CountriesInput
                     name="country"
                     onChange={e => setCountryByCode(e.currentTarget.value)}
                     countries={countries}
                  />
               </>
            ) : (
               <>
                  <p className="mb-2">Land</p>
                  <p className="mb-2" style={{ color: 'gray' }}>
                     <strong>{countries[0]?.name}</strong>
                  </p>
               </>
            )}
         </div>

         <div className="d-flex row">
            <div className="col-12 col-lg-6 mb-16">
               <PID />
            </div>
            {fields.map(field => {
               return (
                  <div key={field.id} className="col-12 col-lg-6 mb-16">
                     <InputWithLabel {...field.inputProps} />
                  </div>
               )
            })}
         </div>
      </section>
   )
}
