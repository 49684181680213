import React, { useState } from 'react';
import {styled} from 'linaria/react'
import VbField from '../../Core/Form/VbField';
import Title from '../../Core/Title';
import { BrightStar, Check, MailOpened, NavArrowRight, SendDiagonal, SendMail } from 'iconoir-react';
import VbInformationBox from '../../Core/Form/VbInformationBox';
import { theme } from '../../Theme';
import SiteButton from '../../Core/SiteButton';
import { useIntl } from '@jetshop/intl';
import NewsletterWelcomeWidget from './NewsletterWelcomeWidget';
import NewsLetterForm from './NewsLetterForm';

const NewsletterRegistrationContainer = styled.div`
   & > div:first-child {
      margin-top: 6rem;
   }

   & > div:last-child {
      margin-bottom: 4rem;
   }

   & > div {
      margin-bottom: 4rem;
   }
`

const NewsletterFormContainer = styled.div`
    background-color: white;
    border-radius: 2rem;
    box-shadow: 0 3px 16px 0 rgba(0,0,0,0.16);

    h1 {
      font-weight: 600;
      font-size: calc(1.1vw + 1rem);
      text-transform: uppercase !important;
    }
`

const SvgWrapper = styled.div`
    height: 42px;
    width: 42px;

    svg {
      height: 100%;
      width: 100%;
    }

    ${theme.below.md}{
      height: ${props => (props.mobileHeight)};
      width: ${props => (props.mobileWidth)};
    }
`

const Container = ({ children }) => {
   return (
      <NewsletterFormContainer className="container mx-0 px-128 py-64">
         {children}
      </NewsletterFormContainer>
   )
}

const NewsletterRegistrationPage = ({ category }) => {
   const [showConfirmPage, setShowConfirmPage] = useState(false)
   const [showWelcomePage, setShowWelcomePage] = useState(false)
   const t = useIntl()

   return (
      <NewsletterRegistrationContainer className="p-0 container d-flex flex-column align-items-center">
         {(() => {
            if (showConfirmPage) {
               return (
                  <VbInformationBox
                     title={'2. ' +  t('newsletter-confirm-title')}
                     subtitle={
                        t('newsletter-confirm-text')
                     }
                     titleIcon={
                        <SvgWrapper
                           mobileHeight="1.75rem"
                           mobileWidth="1.75rem"
                        >
                           <MailOpened
                              strokeWidth={2.0}
                              width={'100%'}
                              height={'100%'}
                           />
                        </SvgWrapper>
                     }
                     content={
                        <div className="d-flex justify-content-center mt-3">
                           <SendMail
                              color="#195178"
                              width={111}
                              height={111}
                           ></SendMail>
                        </div>
                     }
                  ></VbInformationBox>
               )
            } else if (showWelcomePage) {
               return (
                  <NewsletterWelcomeWidget></NewsletterWelcomeWidget>
               )
            } else {
               return (
                  <Container>
                     <div className="mb-16 p-0 d-flex gap-3 align-items-center">
                        <SendDiagonal
                           strokeWidth={2.0}
                           width={38}
                           height={38}
                        />
                        <h1 className="m-0">
                            1. {t('newsletter-title')}
                        </h1>
                     </div>
                     <NewsLetterForm
                        showConfirmPage={setShowConfirmPage}
                        showWelcomePage={setShowWelcomePage}
                     ></NewsLetterForm>
                  </Container>
               )
            }
         })()}
      </NewsletterRegistrationContainer>
   )
}
 
export default NewsletterRegistrationPage;