import React from 'react';
import { styled } from 'linaria/react'
import { theme } from '../../Theme';

const Container = styled.div`
    background-color: white;
    border-radius: 2rem;
    box-shadow: 0 3px 16px 0 rgba(0,0,0,0.16);

    .title {
      font-weight: 600;
      font-size: min(calc(0.9vw + 1.0rem), 32px) !important;
      text-transform: uppercase !important;

      ${theme.below.md}{
         font-size: 1.1rem;
      }
    }

    .sub-title {
      font-weight: 700;
      font-size: min(calc(1.1vw + 0.75rem), 26px);
    }
`


const VbInformationBox = ({ title, subtitle ,titleIcon, content}) => {
   return (
      <Container className="container mx-0 px-128 py-64">
         <div className="mb-16 p-0 d-flex gap-3 align-items-center">
            {titleIcon}
            <h1 className="m-0 title">{title}</h1>
         </div>
         <p className='sub-title'>
            {subtitle}
         </p>
         {content}
      </Container>
   )
}
 
export default VbInformationBox;