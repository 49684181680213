import React, { useState } from 'react';
import {styled} from 'linaria/react'
import { BrightStar, Check, NavArrowRight } from 'iconoir-react';
import { theme } from '../../Theme';
import VbInformationBox from '../../Core/Form/VbInformationBox';
import SiteButton from '../../Core/SiteButton';
import { useIntl } from '@jetshop/intl';

const SvgWrapper = styled.div`
    height: 42px;
    width: 42px;

    svg {
      height: 100%;
      width: 100%;
    }

    ${theme.below.md}{
      height: ${props => (props.mobileHeight)};
      width: ${props => (props.mobileWidth)};
    }
`

const NewsletterWelcomeWidget = () => {
   const t = useIntl()

   return (
      <VbInformationBox
         title={'3. ' + t('newsletter-welcome-title')}
         subtitle={t('newsletter-welcome-text')}
         titleIcon={
            <SvgWrapper mobileHeight="3rem" mobileWidth="3rem">
               <Check strokeWidth={2.0} width={'100%'} height={'100%'} />
            </SvgWrapper>
         }
         content={
            <>
               <div className="d-flex justify-content-center mt-3">
                  <BrightStar
                     color="#59a021"
                     width={111}
                     height={111}
                  ></BrightStar>
               </div>
               <div>
                  <SiteButton
                     style={{ padding: '0.8rem 0.8rem 0.8rem 1.5rem' }}
                     href={'/'}
                     styleType="solid"
                     color="blue"
                     size="large"
                     icon={true}
                  >
                     {t('to-startpage')}
                     <NavArrowRight
                        strokeWidth={2.0}
                        style={{ marginLeft: '1rem' }}
                     ></NavArrowRight>
                  </SiteButton>
               </div>
            </>
         }
      ></VbInformationBox>
   )
}
 
export default NewsletterWelcomeWidget;