import { css } from 'linaria'
import { theme } from '../../Theme'
import { styled } from 'linaria/react'

/** @returns Linaria css*/
export const smallSection = css`
   display: flex;
   flex-direction: column;
   padding: ${theme.space[1]};
   width: 30rem;
   max-width: 100%;
   label {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
   }

   ${theme.below.sm} {
      width: 100%;
   }
`
/** @returns Linaria css*/
export const activeSegment = css`
   position: relative;
   ::before {
      content: '';
      position: absolute;
      left: -1.5rem;
      top: 0;
      bottom: 0;
      width: 3px;
      height: 100%;
      background: ${theme.colors.blue};
      transition: opacity 0.4s ease;
   }
`

/** @returns Styled component*/
export const FieldsWrapper = styled.section`
   display: flex;
   flex-direction: column;
   gap: 1rem;
`

/** @returns Styled component*/
export const FormButtonsContainer = styled.div`
   margin-top: 1rem;
   display: flex;
   flex-wrap: wrap;
   gap: 1rem;

   ${theme.above.md} {
      margin-top: 2rem;
      /* Margin-right on first element(btn/link) in container */
      & > :first-child {
         margin-right: auto;
      }
   }

   /* Full width on btns/links in mobile-view */
   ${theme.below.sm} {
      button {
         width: 100% !important;
      }
      a {
         width: 100% !important;
      }
   }
`

/** @returns Styled component*/
export const FormButtonsContainerSmallMarginTop = styled.div`
   display: flex;
   flex-wrap: wrap;
   gap: 1rem;
   margin-top: 0.5rem;

   ${theme.above.md} {
      margin-top: 1rem;
      /* Margin-right on first element(btn/link) in container */
      & > :first-child {
         margin-right: auto;
      }
   }

   /* Full width on btns/links in mobile-view */
   ${theme.below.sm} {
      button {
         width: 100% !important;
      }
      a {
         width: 100% !important;
      }
   }
`

