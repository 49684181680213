import t from '@jetshop/intl'
import { ErrorMessage, Field } from 'formik'
import { cx, css } from 'linaria'
import { styled } from 'linaria/react'
import React, { useState } from 'react'
import {
   InputError,
   InputPositive,
   ToggleViewPasswordButton,
} from './InputStatusVB'
import { theme } from '../Theme'
import { Above } from '@jetshop/ui/Breakpoints'
import { Below } from '@jetshop/ui/Breakpoints'


// Label-content styles
const labelContentSmallStyle = {
   marginBottom: '0.4rem',
}
const labelContentSmall = css`
   ${labelContentSmallStyle}
`
const labelContentLarge = css`
   margin-bottom: 0.75rem;
   ${theme.below.sm} {
      // Show labelContentSmallStyle in mobile
      ${labelContentSmallStyle}
   }
`

// Input-styles
const smallInputStyle = {
   minHeight: '2.75rem',
   borderRadius: '0.25rem',
   border: 'solid 2px #707070',
   textIndent: '1rem',
   fontSize: '1rem',
}
const smallInput = css`
   ${smallInputStyle}
`
const largeInput = css`
   min-height: 3.75rem;
   border-radius: 0.25rem;
   border: solid 3px #707070;
   text-indent: 1rem;
   font-size: 1.25rem;
   ${theme.below.sm} {
      // Show smallInput in mobile
      ${smallInputStyle}
   }
`
const Input = styled('input')`
   background-color: #fbfbfb;
   width: 100%;
   font-family: var(--font-grotesk) !important;
   &.disabled {
      background: #ffffff;
      color: #808080;
   }
   &::placeholder {
      color: #000;
      opacity: 0.43;
   }
   &.error-background {
      background-color: #ffe9e6;
   }
   &.error-border {
      border: solid 3px #a61200;
   }
`

// Label-styles
const smallLabelStyle = {
   fontSize: '1rem',
   fontWeight: '600',
}
const smallLabel = css`
   ${smallLabelStyle}
`
const largeLabel = css`
   font-size: 1.4rem;
   font-weight: 600;
   ${theme.below.sm} {
      ${smallLabelStyle}
   }
`
export const Label = styled('label')`
   display: block;
   font-family: var(--font-industry);
   display: block;
   .req {
   }
   &.disabled {
      color: #808080;
   }
`

export const ErrorSpan = styled('span')`
   color: #a61200;
   font-family: var(--font-industry);
   font-size: 13px;
   font-weight: 300;
   ${theme.above.sm} {
      font-size: 14px;
   }
`

const InputWithLabelVB = ({
   label,
   placeholder,
   disabled,
   error,
   success,
   warning,
   loading,
   name,
   required,
   type,
   disableValidation,
   hideLabel,
   className,
   smallField,
   // defaultValue,
   ...props
}) => {
   const [currentType, setType] = useState(type)

   return (
      <div className={className}>
         <div
            className={cx(
               smallField ? labelContentSmall : labelContentLarge,
               'd-flex justify-content-between align-items-center'
            )}
         >
            {!hideLabel && (
               <Label
                  className={cx(
                     smallField ? smallLabel : largeLabel,
                     disabled && 'disabled'
                  )}
                  htmlFor={name}
               >
                  <span>{label || name}</span>
                  {required && <span className="req">*</span>}
               </Label>
            )}
            {/* Possible error-message on top in larger viewport */}
            <Above breakpoint="md">
               {!disableValidation && (
                  <ErrorSpan>
                     <ErrorMessage name={name} />
                  </ErrorSpan>
               )}
            </Above>
         </div>

         <Field
            className={cx(disabled && 'disabled')}
            id={name}
            name={name}
            render={({ field, form: { touched, errors } }) => {
               return (
                  <div>
                     <Input
                        // defaultValue={defaultValue}
                        {...props}
                        {...field}
                        id={name}
                        disabled={disabled}
                        type={currentType}
                        placeholder={placeholder || ''}
                        aria-label={name || label}
                        className={cx(
                           smallField ? smallInput : largeInput,
                           errors?.[name] && touched?.[name]
                              ? 'error-background error-border'
                              : ''
                        )}
                     />
                     {type === 'password' && (
                        <ToggleViewPasswordButton
                           smallField={smallField}
                           className={
                              currentType === 'password' ? 'hidden' : 'visible'
                           }
                           onClick={() => {
                              currentType === 'password'
                                 ? setType('text')
                                 : setType('password')
                           }}
                        />
                     )}
                     {!disableValidation && (
                        <>
                           {touched?.field?.name && errors?.field?.name && (
                              <InputError />
                           )}
                           {touched?.field?.name && !errors?.field?.name && (
                              <InputPositive />
                           )}
                        </>
                     )}
                  </div>
               )
            }}
         />
         {/* Possible error-message in bottom in smaller viewport */}
         <Below breakpoint="md">
            {!disableValidation && (
               <ErrorSpan>
                  <ErrorMessage name={name} />
               </ErrorSpan>
            )}
         </Below>
      </div>
   )
}

export default InputWithLabelVB